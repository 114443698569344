<template>
  <div class="how-to-cont">
    <div class="sticky">
      <div class="p-5 bg-white">
        <div class="flex items-center justify-center relative p-3">
          <button @click="goBack" class="cursor-pointer p-3 absolute left-0 border-none bg-transparent flex items-center">
            <feather-icon icon="ArrowLeftIcon" class="text-primary2" />&nbsp; &nbsp; Go back to Fresh App
          </button>
        </div>
      </div>
   </div>
    <iframe
      id="how-to"
      src="https://answers.freshclinics.com.au/knowledge"
      frameBorder="0"
      width="100%"></iframe>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      try {
        const user = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`
        );
        const role = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
        );
        const userObject = JSON.parse(user);
        let prefix;

        if (userObject.userType) prefix = userObject.userType
        if (role && role.toLocaleLowerCase() === 'org owner') prefix = 'org-owner'

        this.$router.push(`/${prefix}/resources`);
      } catch (error) {
        console.log(' Error:: ', error)
      }
    }
  },
  beforeCreate() {
    this.$vs.loading();
  },
  mounted () {
    const iframe = document.getElementById('how-to')
    if (iframe) {
      iframe.addEventListener('load', () => {
        this.$vs.loading.close();
      })
    }
  },
}
</script>

<style lang="scss">
.how-to-cont {
  iframe {
    height: 93vh;
  }
}
</style>
